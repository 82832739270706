
import { Options, Vue } from "vue-property-decorator";
import api from "@/api/projectinfo-safedanger";

@Options({})
export default class extends Vue {
  private finishAmount: any[] = [];
  private finishPrecent: any[] = [];
  private date: any[] = [];
  private flag = true;

  mounted() {
    this.getDataChartOne();
    this.getDataChartTwo();
    this.getDataChartThree();
  }

  getDataChartOne() {
    api
      .reportBillCountAllCheck({
        projectId: this.$store.state.project.projectinfoDetail.id,
      })
      .then((res: any) => {
        let arr: any[] = [];
        let sum = 0;
        if (res.data.itemList.length == 0) {
          arr = [{ value: 0, name: "暂无问题" }];
        } else {
          res.data.itemList.map((item: any) => {
            sum += parseInt(item.number);
          });
          res.data.itemList.map((item: any, index: any) => {
            arr[index] = { value: item.number, name: item.name };
          });
        }
        this.initChartOne(arr, sum);
      });
  }
  initChartOne(arr: any, sum: any) {
    var echarts = require("echarts");
    var myChart = echarts.init(document.getElementById("chart-1"), "light");
    // 指定图表的配置项和数据
    var option = {
      // 提示框
      tooltip: {
        show: true,
        // formatter: '{b} </br> 销量{c}件 </br> 占比{d}%' // 提示框显示内容,此处{b}表示各数据项名称，此项配置为默认显示项，{c}表示数据项的值，默认不显示，({d}%)表示数据项项占比，默认不显示。
        // formatter: '{b} </br> 问题数量{c}个 </br>'
        formatter: "安全问题分析 <br/>{b} : {c}个 <br/>占比{d}%",
      },
      title: {
        text: "累计问题",
        left: "41%",
        top: "50%",
        textStyle: {
          color: "#828895",
          fontSize: 14,
          align: "center",
        },
      },
      grid: {
        width: "83%",
        height: "65%",
      },
      graphic: {
        type: "text",
        left: "center",
        top: "40%",
        style: {
          text: sum,
          textAlign: "center",
          fill: "#828895",
          fontSize: 20,
          fontWeight: 700,
        },
      },

      // 系列列表
      series: [
        {
          name: "圆环图系列名称", // 系列名称
          type: "pie", // 系列类型
          center: ["50%", "50%"], // 饼图的中心（圆心）坐标，数组的第一项是横坐标，第二项是纵坐标。[ default: ['50%', '50%'] ]
          radius: ["45%", "70%"], // 饼图的半径，数组的第一项是内半径，第二项是外半径。[ default: [0, '75%'] ]
          hoverAnimation: true, // 是否开启 hover 在扇区上的放大动画效果。[ default: true ]// 圆环图的颜色
          label: {
            // 饼图图形上的文本标签，可用于说明图形的一些数据信息，比如值，名称等.
            normal: {
              show: true, // 是否显示标签[ default: false ]
            },
          },
          data: arr, // 系列中的数据内容数组。
        },
      ],
    };
    myChart.setOption(option);
  }
  getDataChartTwo() {
    api
      .inspectionWayRecordAnalysis({
        projectId: this.$store.state.project.projectinfoDetail.id,
        status: 0,
      })
      .then((res: any) => {
        res.data.map((item: any) => {
          item.finishPrecent = parseFloat(item.finishPrecent).toFixed(2);
          this.finishAmount.push(item.finishAmount);
          this.finishPrecent.push(item.finishPrecent);
          this.date.push(item.date);
        });

        this.initChartTwo(this.finishAmount, this.finishPrecent, this.date);
      })
      .catch(() => {
        this.flag = false;
      });
  }
  initChartTwo(check: any, percent: any, date: any) {
    var echarts = require("echarts");
    var myChart = echarts.init(document.getElementById("chart-2"));

    myChart.setOption({
      tooltip: {
        trigger: "axis",
        formatter: (params: any) => {
          return `${params[0].name}<br/>
                      <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-image: linear-gradient(to bottom,#2e56f2,#5293ec);"></span>${params[0].seriesName}：${params[0].value}<br/>
                      ${params[1].marker}${params[1].seriesName}：${params[1].value}%
                   `;
        },
      },
      legend: {
        name: ["完成量", "完成率"],
        textStyle: {
          color: "black",
        },
        left: "center",
        top: "0%",
        right: 0,
      },
      grid: {
        left: "9%",
        top: "20%",
        width: "82%",
        height: "65%",
      },
      xAxis: {
        type: "category",
        data: date,
        // boundaryGap: false,
        axisLine: {
          lineStyle: {
            color: "black",
          },
        },
        axisLabel: {
          color: "black",
          // interval: 5,
        },
      },
      yAxis: [
        {
          type: "value",
          axisLine: {
            lineStyle: {
              color: "black",
            },
          },
          name: "完成量",
          nameTextStyle: {
            color: "black",
            padding: 0, // 内边距
          },
          splitLine: {
            show: false,
          },
        },
        {
          type: "value",
          name: "完成率",
          nameTextStyle: {
            color: "black",
            padding: 0, // 内边距
          },
          axisLine: {
            lineStyle: {
              color: "black",
            },
          },
          splitLine: {
            show: false,
          },
        },
      ],
      series: [
        {
          name: "完成量",
          type: "line",
          itemStyle: {
            color: "#3F7CFF ",
          },
          data: check,
        },
        {
          name: "完成率",
          type: "line",
          yAxisIndex: 1,
          itemStyle: {
            color: "#00B886 ",
          },
          data: percent,
        },
      ],
    });
  }
  getDataChartThree() {
    api
      .kanbanSafeRecentBill({
        projectId: this.$store.state.project.projectinfoDetail.id,
        limit: 6,
      })
      .then((res) => {
        var dateArr: any[] = [];
        var dataArr: any[] = [];
        res.data.map((item: any) => {
          dateArr.push(item.name);
          dataArr.push(item.value);
        });
        this.initChartsThree(dateArr, dataArr);
      });
  }
  initChartsThree(dateArr: any, dataArr: any) {
    var echarts = require("echarts");
    var myChart = echarts.init(document.getElementById("chart-3"));

    myChart.setOption({
      tooltip: {
        trigger: "axis",
        formatter: (params: any) => {
          return `${params[0].name}<br/>
              <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-image: linear-gradient(to bottom,#21ecd6,#21ecd6);"></span>安全问题：${params[0].value}<br/>`;
        },
      },
      grid: {
        width: "90%",
        height: "70%",
        top: "8%",
        left: "7%",
      },
      yAxis: {
        type: "value",
        splitLine: {
          show: true,
          lineStyle: {
            type: "dashed",
            color: "#023c7a",
            opacity: 0.1,
          },
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: "#023c7a",
          },
        },
        axisLabel: {
          color: "black",
        },
      },
      xAxis: {
        type: "category",
        data: dateArr,
        splitLine: {
          show: false,
        },
        axisLine: {
          lineStyle: {
            color: "#023c7a",
          },
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          color: "black",
          formatter: function (params: any) {
            var newParamsName = ""; // 最终拼接成的字符串
            var paramsNameNumber = params.length; // 实际标签的个数
            var provideNumber = 4; // 每行能显示的字的个数
            var rowNumber = Math.ceil(paramsNameNumber / provideNumber); // 换行的话，需要显示几行，向上取整
            /**
             * 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
             */
            // 条件等同于rowNumber>1
            if (paramsNameNumber > provideNumber) {
              /** 循环每一行,p表示行 */
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = ""; // 表示每一次截取的字符串
                var start = p * provideNumber; // 开始截取的位置
                var end = start + provideNumber; // 结束截取的位置
                // 此处特殊处理最后一行的索引值
                if (p == rowNumber - 1) {
                  // 最后一次不换行
                  tempStr = params.substring(start, paramsNameNumber);
                } else {
                  // 每一次拼接字符串并换行
                  tempStr = params.substring(start, end) + "\n";
                }
                newParamsName += tempStr; // 最终拼成的字符串
              }
            } else {
              // 将旧标签的值赋给新标签
              newParamsName = params;
            }
            // 将最终的字符串返回
            return newParamsName;
          },
        },
      },
      series: [
        {
          type: "line",
          // smooth: true,
          itemStyle: {
            color: "#FF983C ",
          },
          data: dataArr,
        },
      ],
    });
  }
}
